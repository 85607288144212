
import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import { Notification } from "@/models/notification.interface";
import { UserValidations } from "@/mixins/user-validations";
import { VueEditor } from "vue2-editor";
import { EditorImages } from "@/mixins/editor-images";
import { Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    Loader,
    VueEditor,
  },
})
export default class UserWithdrawalAccounts extends mixins(
  Navigation,
  UserValidations,
  CustomDate,
  EditorImages,
  StyleCheck
) {
  $refs!: {
    accountForm: HTMLFormElement;
    accountUpdateForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  dialog = false;
  dialogUpdate = false;
  showErrorAnswer = false;
  info = "";
  currentAccount: any = null;
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private changePage() {
    this.navigate("/user/withdrawal/accounts/" + this.pagination.page);
  }

  private get totalPages() {
    const total =
      this.pagination.totalItems /
      Number(process.env.VUE_APP_DEFAULT_PAGINATION);
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  @Watch("$route.params", { deep: true })
  private async handler() {
    this.loader = true;
    await this.getAccounts(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
    window.scrollTo(0, 0);
  }

  private async created() {
    this.loader = true;

    await this.getAccounts(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );

    await this.$store
      .dispatch("users/getUserBalanceById", this.userID)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Users.fetchError.userBalance", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loader = false;
    window.scrollTo(0, 0);
  }

  private async getAccounts(page: string, size: number) {
    await this.$store
      .dispatch("users/getUserAccounts", { id: this.userID, page, size })
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Views.uw-e1", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get userID(): any {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.id;
    } else {
      return {};
    }
  }

  private get accounts() {
    let response = this.$store.getters["users/getUserAccounts"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    return response;
  }

  private openConfigAccount(account: any) {
    this.currentAccount = account;
    this.dialog = true;
  }

  private openConfigUpdateAccount(account: any) {
    this.currentAccount = account;
    this.info = account.account.value;
    this.dialogUpdate = true;
  }

  private clearDialogAccount() {
    this.currentAccount = null;
    this.showErrorAnswer = false;
    this.info = "";
    this.dialog = false;
    this.dialogUpdate = false;
  }

  private validatedDescription() {
    if (this.info !== "" && this.info !== undefined && this.info !== null) {
      return true;
    } else {
      return false;
    }
  }

  private async createUWA() {
    this.loading = true;
    if (this.$refs.accountForm.validate() && this.validatedDescription()) {
      await this.$store
        .dispatch("users/createUWA", {
          platform: {
            id: this.currentAccount.platform.id,
          },
          account: {
            value: this.info,
          },
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("Views.pp-success1"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getAccounts(
            this.$route.params.page,
            this.pagination.itemsPerPage
          );
          this.clearDialogAccount();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.pp-error3"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
          this.showErrorAnswer = false;
        });
    } else {
      if (this.validatedDescription()) {
        this.showErrorAnswer = false;
      } else {
        this.showErrorAnswer = true;
      }

      this.loading = false;
    }
  }

  private async updateUWA() {
    this.loading = true;
    if (
      this.$refs.accountUpdateForm.validate() &&
      this.validatedDescription()
    ) {
      await this.$store
        .dispatch("users/updateUWA", {
          id: this.currentAccount.account.id,
          account: {
            value: this.info,
          },
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("Views.pp-success1"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getAccounts(
            this.$route.params.page,
            this.pagination.itemsPerPage
          );
          this.clearDialogAccount();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.pp-error3"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
          this.showErrorAnswer = false;
        });
    } else {
      if (this.validatedDescription()) {
        this.showErrorAnswer = false;
      } else {
        this.showErrorAnswer = true;
      }

      this.loading = false;
    }
  }
}
